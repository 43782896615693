import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    backgroundColor: "rgba(255,255, 255, 0.7)",
    zIndex: 3000,
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    color: theme.palette.error.main,
    fontSize: 32,
    fontWeight: 700,
  },
}));
