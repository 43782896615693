import "./App.css";
import { CssBaseline, Stack } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import Menu from "./components/Menu/Menu";
import AppRouter from "./router";
import { theme } from "./styles/styles";
import { useContext, useMemo } from "react";
import { AxiosInterceptorsContext } from "./context/AxiosInterceptors";
import GlobalError from "./components/GlobalError/GlobalError";

function App() {
  const { token, isLoading, isGlobalError, setIsGlobalError } = useContext(
    AxiosInterceptorsContext
  );

  return useMemo(() => {
    return (
      <ThemeProvider theme={theme}>
        <Stack className="root">
          <CssBaseline />
          {token && <Menu resetGlobalError={setIsGlobalError} />}
          <Stack className="content">
            {isGlobalError && <GlobalError />}
            <AppRouter token={token} />
          </Stack>
        </Stack>
      </ThemeProvider>
    );
    // eslint-disable-next-line
  }, [token, isLoading, isGlobalError]);
}

export default App;
