import { lazy, Suspense } from "react";
import { Route, Switch } from "react-router";
import ProgressPage from "./components/ProgressPage/ProgressPage";

import { PATHS } from "./constants";
const Exercises = lazy(() => import("./pages/Exercises/Exercises"));
const Exercise = lazy(() => import("./pages/Exercise/Exercise"));
const Login = lazy(() => import("./pages/Login/Login"));
const Settings = lazy(() => import("./pages/Settings/Settings"));
const Training = lazy(() => import("./pages/Training/Training"));
const Trainings = lazy(() => import("./pages/Trainings/Trainings"));
const NotFound = lazy(() => import("./pages/NotFound/NotFound"));
const Users = lazy(() => import("./pages/Users/Users"));
const User = lazy(() => import("./pages/User/User"));
const Achievements = lazy(() => import("./pages/Achievements/Achievements"));
const Achievement = lazy(() => import("./pages/Achievement/Achievement"));
const Notifications = lazy(() => import("./pages/Notifications/Notifications"));
const NotificationQuotes = lazy(() => import("./pages/Notification/NotificationQuotes"));
const NotificationRegular = lazy(() => import("./pages/Notification/NotificationRegular"));
const Dashboard = lazy(() => import("./pages/Dashboard/Dashboard"));

const AppRouter = ({ token }) => {
  return (
    <Suspense fallback={<ProgressPage />}>
      {token ? (
        <Switch>
          <Route exact path={PATHS.home} component={Dashboard} />
          <Route exact path={PATHS.exercises} component={Exercises} />
          <Route exact path={PATHS.addExercise} component={Exercise} />
          <Route exact path={PATHS.editExercise} component={Exercise} />
          <Route exact path={PATHS.trainings} component={Trainings} />
          <Route exact path={PATHS.addTraining} component={Training} />
          <Route exact path={PATHS.editTraining} component={Training} />
          <Route exact path={PATHS.users} component={Users} />
          <Route exact path={PATHS.editUser} component={User} />
          <Route exact path={PATHS.settings} component={Settings} />
          <Route exact path={PATHS.achievements} component={Achievements} />
          <Route exact path={PATHS.addAchievement} component={Achievement} />
          <Route exact path={PATHS.editAchievement} component={Achievement} />
          <Route exact path={PATHS.notifications} component={Notifications} />
          <Route exact path={PATHS.editNotificationRegular} component={NotificationRegular} />
          <Route exact path={PATHS.addNotificationRegular} component={NotificationRegular} />
          <Route exact path={PATHS.editNotificationQuotes} component={NotificationQuotes} />
          <Route exact path={PATHS.addNotificationQuotes} component={NotificationQuotes} />
          <Route exact path={PATHS.dashboard} component={Dashboard} />
          <Route exact path="*" component={NotFound} />
        </Switch>
      ) : (
        <Switch>
          <Route
            exact
            path={[
              PATHS.home,
              PATHS.forgotPassword,
              PATHS.newPassword,
              PATHS.temporaryPassword,
              PATHS.registration,
            ]}
            component={Login}
          />
          <Route exact path="*" component={NotFound} />
        </Switch>
      )}
    </Suspense>
  );
};

export default AppRouter;
