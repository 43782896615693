import axios from "axios";
import React, { useReducer } from "react";
import { createContext } from "react";
import { API_URL } from "../constants";

export const GlobalStateContext = createContext();

export const GlobalState = ({ children }) => {
  const initialState = {
    list: {
      exercises: { data: [], totalCount: 0, currentPage: 1, activeTitle: null },
      exercisesForTraining: { data: [], totalCount: null, currentPage: 1 },
      trainings: { data: [], totalCount: 0, currentPage: 1, activeTitle: null },
      achievements: { data: [], totalCount: 0, currentPage: 1, activeTitle: null },
      notifications: { data: [] },
      users: { data: [], totalCount: 0, currentPage: 1, activeTitle: null },
      dashboard: { data: {} },
      dashboardRegistrations: { data: [] },
      dashboardSubscriptions: { data: [] },
      userTransactions: { activeTitle: null },
      userStatistics: { activeTitle: null },
    },
    item: {
      user: null,
      exercise: null,
      training: null,
      multiplierPageSize: null,
    },
  };

  const SET_LIST = "SET_LIST";
  const SET_ITEM = "SET_ITEM";
  const INCREASE_LIST = "INCREASE_LIST";
  const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
  const SET_ACTIVE_TITLE = "SET_ACTIVE_TITLE";

  const reducer = (state, action) => {
    const {
      nameProperty,
      payload,
      totalCount,
      type,
      currentPage,
      activeTitle,
    } = action;
    switch (type) {
      case INCREASE_LIST:
        return {
          ...state,
          list: {
            ...state.list,
            [nameProperty]: {
              ...state.list[nameProperty],
              data: [...state.list[nameProperty].data, ...payload],
              totalCount,
            },
          },
        };
      case SET_LIST:
        return {
          ...state,
          list: {
            ...state.list,
            [nameProperty]: {
              ...state.list[nameProperty],
              data: payload,
              totalCount: totalCount,
              currentPage: currentPage || state.list[nameProperty].currentPage,
            },
          },
        };
      case SET_ITEM:
        return {
          ...state,
          item: { ...state.item, [nameProperty]: payload },
        };
      case SET_CURRENT_PAGE: {
        return {
          ...state,
          list: {
            ...state.list,
            [nameProperty]: { ...state.list[nameProperty], currentPage },
          },
        };
      }
      case SET_ACTIVE_TITLE:
        return {
          ...state,
          list: {
            ...state.list,
            [nameProperty]: { ...state.list[nameProperty], activeTitle },
          },
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const getList = async (endpoint, nameProperty, isIncreaseList) => {
    const payload = await axios.get(`${API_URL}/${endpoint}`);
    const data = payload?.data?.results || payload?.data;

    const totalCount = payload?.data?.total;
    isIncreaseList
      ? dispatch({
          type: INCREASE_LIST,
          payload: data,
          nameProperty,
          totalCount,
        })
      : dispatch({ type: SET_LIST, payload: data, nameProperty, totalCount });
  };

  const getItem = async (endpoint, nameProperty) => {
    const { data } = await axios.get(`${API_URL}/${endpoint}`);
    dispatch({ type: SET_ITEM, payload: data, nameProperty });
  };

  const postItem = async (endpoint, data) => {
    return await axios.post(`${API_URL}/${endpoint}`, data);
  };

  const updateItem = async (endpoint, data) => {
    return await axios.put(`${API_URL}/${endpoint}`, data);
  };

  const deleteItem = async (endpoint) => {
    return await axios.delete(`${API_URL}/${endpoint}`);
  };

  const setItem = (nameProperty, data) => {
    dispatch({ type: SET_ITEM, payload: data, nameProperty });
  };

  const setCurrentPage = (nameProperty, currentPage) => {
    dispatch({ type: SET_CURRENT_PAGE, nameProperty, currentPage });
  };

  const resetList = (nameProperty) => {
    dispatch({
      type: SET_LIST,
      payload: [],
      nameProperty,
      totalCount: null,
      currentPage: 1,
    });
  };

  const setActiveTitle = (nameProperty, activeTitle) => {
    dispatch({
      type: SET_ACTIVE_TITLE,
      nameProperty,
      activeTitle: activeTitle,
    });
  };

  const value = {
    state,
    getList,
    postItem,
    getItem,
    updateItem,
    deleteItem,
    setItem,
    setCurrentPage,
    resetList,
    setActiveTitle,
  };

  return (
    <GlobalStateContext.Provider value={value}>
      {children}
    </GlobalStateContext.Provider>
  );
};
