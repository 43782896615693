import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import { useStyles } from "./styles";

const ProgressPage = ({className}) => {
  const classes = useStyles();

  return (
    <Box className={`${classes.root} ${className? className : ""}`}>
      <CircularProgress size={80} thickness={5} />
    </Box>
  );
};

export default ProgressPage;
