import axios from "axios";
import { API_URL, PATHS } from "../constants";
import TokenService from "../services/token.service";

export default function useAuth() {
  const logOut = () => {
    TokenService.removeTokens();
    window.location.assign(PATHS.home);
  };

  const loginUser = async (data) =>
    await axios.post(`${API_URL}/auth/login`, data);

  const getTemporaryPassword = async (data) => {
    return await axios.post(`${API_URL}/auth/forgot-password`, data);
  };

  const checkTempoPassword = async (data) => {
    return await axios.post(`${API_URL}/auth/temporary-password`, data);
  };

  const resetPassword = async (data, tempoToken) => {
    return await axios.post(`${API_URL}/auth/reset-password`, data, {
      headers: { common: { Authorization: `Bearer ${tempoToken}` } },
    });
  };

  const updateAccessToken = async () => {
    return await axios.post(`${API_URL}/auth/refresh`, {
      refreshToken: TokenService.getRefreshToken(),
    });
  };

  return {
    loginUser,
    logOut,
    getTemporaryPassword,
    resetPassword,
    checkTempoPassword,
    updateAccessToken,
  };
}
