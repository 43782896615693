import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  typography: {
    fontFamily: "Inter, sans-serif" ,
    fontSize: 14,
    h2: {
      fontSize: 30,
      fontWeight: 700,
    },
    h3: {
      fontWeight: 700,
      fontSize: "1.4rem",
    },
  },
  palette: {
    primary: {
      main: "#6875F5",
      light: "#E5EDFF",
      dark: "#362F78",
      contrastText: "#fff",
      active: "#1A202E",
    },
    success: {
      main: "#0E9F6E",
      light: "#DEF7EC",
      dark: "#014737",
      contrastText: "#fff",
    },
    error: {
      main: "#F05252",
      light: "#FDE8E8",
      dark: "#771D1D",
      contrastText: "#fff",
    },
    text: {
      primary: "#141A28",
    },
    grey: {
      900: "#1A202E",
      500: "#64748B",
      400: "#97A6BA",
      200: "#E2E8F0",
    },
  },
  components: {
    MuiListItem: {
      styleOverrides: {
        root: {
          "& .MuiTypography-root": {
            fontWeight: 500,
            color: "#64748B",
          },
          "&.Mui-selected, &:hover": {
            backgroundColor: "#F1F5F9",
            borderRight: "2px solid #6875F5",
            "& .MuiTypography-root": {
              color: "#6875F5",
            },
            "& path": {
              fill: "#6875F5",
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: "none",
          "&.Mui-disabled": {
            cursor: "wait",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          "&.Mui-disabled": {
            cursor: "wait",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: 8,
            borderColor: "#E2E8F0",
          },
          "& .MuiInputAdornment-root": {
            margin: 0,
            "& button": {
              backgroundColor: "white",
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            },
          },
          "& label": {
            fontSize: 14,
            transform: "translate(14px, 10px) scale(1)",
            "&.MuiInputLabel-shrink": {
              transform: "translate(14px, -9px) scale(0.75)",
            },
          },
          "& input": {
            padding: "8px 16px",
            backgroundColor: "#fff",
            borderRadius: 8,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: "#F1F5F9",
          "& .MuiTableCell-root": {
            backgroundColor: "#F1F5F9",
            "& button": {
              fontSize: 14,
            },
          },
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          "@media (min-width: 0px)": {
            bottom: "auto",
            left: "auto",
            right: 27,
            top: 38,
            width: 432,
            borderRadius: 8,
            padding: "8px 16px",
            "& svg": {
              fill: "#fff",
            },
            "& button": {
              padding: 4,
            },
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          "& > .MuiDrawer-paper": {
            position: "static",
            width: "100%",
          },
        },
      },
    },
  },
});
