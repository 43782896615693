import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { AxiosInterceptors } from "./context/AxiosInterceptors";
import { GlobalState } from "./context/Store";

ReactDOM.render(
  <AxiosInterceptors>
    <GlobalState>
      <Router>
        <App />
      </Router>
    </GlobalState>
  </AxiosInterceptors>,
  document.getElementById("root")
);
