import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";

import { DRAWER_WIDTH, MENU_ITEMS } from "../../constants";
import Logo from "../../assets/img/logo.png";
import { useStyles } from "./styles";

const Menu = ({ resetGlobalError }) => {
  const classes = useStyles();
  const { pathname } = useLocation();

  return (
    <Drawer
      sx={{
        width: DRAWER_WIDTH,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: DRAWER_WIDTH,
          boxSizing: "border-box",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar className={classes.logo}>
        <img src={Logo} alt="Logo" width={276} height={62}/>
      </Toolbar>

      <List className={classes.menuList}>
        {MENU_ITEMS.map((menuItem) => (
          <Link
            to={menuItem.path[0]}
            key={menuItem.name}
            className={classes.menuItem}
            onClick={() => resetGlobalError(false)}
          >
            <ListItem
              button
              selected={menuItem.path.some((pathItem) => {
                return (
                  pathname.replace(/\d*$/, "") === pathItem.replace(/:id/, "")
                );
              })}
            >
              <ListItemIcon>{menuItem.icon}</ListItemIcon>
              <ListItemText primary={menuItem.name} />
            </ListItem>
          </Link>
        ))}
      </List>
    </Drawer>
  );
};

export default Menu;
