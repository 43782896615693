import { Stack } from "@mui/material";
import { useStyles } from "./styles";

const GlobalError = () => {
  const classes = useStyles();
  return (
    <Stack className={classes.root}>
      <Stack className={classes.content}>Something went wrong</Stack>
    </Stack>
  );
};

export default GlobalError;
