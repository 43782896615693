import axios, { CancelToken } from "axios";
import React, { createContext, useMemo, useRef, useState } from "react";

import { API_URL, ERROR_MESSAGE } from "../constants";
import useAuth from "../hooks/useAuth";
import TokenService from "../services/token.service";

const AxiosInterceptorsContext = createContext();
const { Provider } = AxiosInterceptorsContext;

const AxiosInterceptors = ({ children }) => {
  const accessToken =
    TokenService.getAccessToken() === "undefined"
      ? null
      : TokenService.getAccessToken();

  const { logOut, updateAccessToken } = useAuth();
  const [token, setToken] = useState(accessToken);
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isGlobalError, setIsGlobalError] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);

  const idTimeoutSuccessMessage = useRef(null);
  const idTimeoutErrorMessage = useRef(null);

  const closeAlertMessage = () => {
    setSuccessMessage(null);
    setErrorMessage(null);
    clearInterval(idTimeoutSuccessMessage.current);
    clearInterval(idTimeoutErrorMessage.current);
  };

  useMemo(() => {
    axios.defaults.headers.post["Content-Type"] = "application/json";
    axios.defaults.baseUrl = API_URL;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios.defaults.headers.common["isLogin"] = true;
    let call = {};

    axios.interceptors.request.use(
      function (config) {
        //cancel duplicate GET requests
        if (
          config.method === "get" &&
          config.method === call.method &&
          JSON.stringify(config.params) === JSON.stringify(call.params) &&
          config.url === call.url &&
          !config.url.includes("training-exercise") &&
          !config.url.includes("training") &&
          !config.url.includes("user") &&
          !config.url.includes("achievement")
        ) {
          return {
            ...config,
            cancelToken: new CancelToken((cancel) =>
              cancel("Cancel repeated request")
            ),
          };
        } else {
          let url = new URL(config?.url);
          const pathname = url.pathname.match(/^\/[^/]*/);
          setLoading(`${config.method}${pathname}`);
          setErrorMessage(null);
          !config.method === "get" && setSuccessMessage(null);
          call = {
            url: config.url,
            method: config.method,
            params: config.params,
          };
        }

        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        const isMethodGet = response?.config?.method === "get";
        const responseMessage = response?.data?.message;
        setLoading(false);
        !isMethodGet && responseMessage && setSuccessMessage(responseMessage);
        idTimeoutSuccessMessage.current = setTimeout(
          () => setSuccessMessage(null),
          5000
        );
        return response;
      },

      async (error) => {
        setLoading(false);
        const config = error.response?.config;
        const status = error.response?.status;
        const isloginRoute = config?.url.includes("/auth/login");
        const message = error.response
          ? error.response.data.message
          : error.message;

        if (!message) {
          setIsGlobalError(true);
          return false;
        }

        if (message === "Refresh token expired") {
          setToken(null);
          setErrorMessage(null);
          logOut();
        }

        //send refresh token when access token was expired
        if (!isloginRoute && status === 401 && !config._retry) {
          config._retry = true;
          try {
            const res = await updateAccessToken();
            const { accessToken } = res.data;
            TokenService.setAccessToken(accessToken);
            config.headers["Authorization"] = `Bearer ${accessToken}`;
            setToken(accessToken);
            return axios(config);
          } catch (e) {
            setToken(null);
            setErrorMessage(null);
            logOut();
          }
        }

        if (message === "Network Error" || status >= 500) {
          setIsGlobalError(true);
        }

        isloginRoute && ERROR_MESSAGE[message]
          ? setErrorMessage(ERROR_MESSAGE[message])
          : setErrorMessage(message);

        idTimeoutErrorMessage.current = setTimeout(
          () => setErrorMessage(null),
          10000
        );

        return Promise.reject(error);
      }
    );
    // eslint-disable-next-line
  }, [token]);

  const value = useMemo(
    () => ({
      token,
      isLoading,
      errorMessage,
      setErrorMessage,
      isGlobalError,
      setIsGlobalError,
      setSuccessMessage,
      successMessage,
      closeAlertMessage,
    }),
    // eslint-disable-next-line
    [isLoading, errorMessage, isGlobalError, successMessage, token]
  );

  return <Provider value={value}>{children}</Provider>;
};

export { AxiosInterceptors, AxiosInterceptorsContext };
