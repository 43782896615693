import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import GradeIcon from '@mui/icons-material/Grade';
import SettingsIcon from '@mui/icons-material/Settings';
import DashboardIcon from '@mui/icons-material/Dashboard';
import NotificationsIcon from '@mui/icons-material/Notifications';
import moment from 'moment';

export const API_URL = process.env.REACT_APP_API_URL;

export const PATHS = {
  home: '/',
  forgotPassword: '/forgot-password',
  temporaryPassword: '/temporary-password',
  newPassword: '/new-password',
  registration: '/registration',
  exercises: '/exercises',
  addExercise: '/exercise-add/',
  editExercise: '/exercise-edit/:id',
  trainings: '/trainings',
  addTraining: '/training-add/',
  editTraining: '/training-edit/:id',
  users: '/users',
  editUser: '/user-edit/:id',
  achievements: '/achievements',
  addAchievement: '/achievement-add/',
  editAchievement: '/achievement-edit/:id',
  settings: '/settings',
  login: '/login',
  notifications: '/notifications',
  addNotificationQuotes: '/notificationsQuotes-add/',
  editNotificationQuotes: '/notificationsQuotes-edit/:id',
  addNotificationRegular: '/notificationsRegular-add/',
  editNotificationRegular: '/notificationsRegular-edit/:id',
  dashboard: '/dashboard',
};

export const DRAWER_WIDTH = 216;

export const MENU_ITEMS = [
  {
    name: 'Dashboard',
    icon: <DashboardIcon />,
    path: [PATHS.dashboard, PATHS.home],
  },
  {
    name: 'Exercises',
    icon: <FitnessCenterIcon />,
    path: [PATHS.exercises, PATHS.addExercise, PATHS.editExercise],
  },
  {
    name: 'Trainings',
    icon: <ListAltIcon />,
    path: [PATHS.trainings, PATHS.addTraining, PATHS.editTraining],
  },
  {
    name: 'Users',
    icon: <SupervisorAccountIcon />,
    path: [PATHS.users, PATHS.editUser],
  },
  {
    name: 'Achievements',
    icon: <GradeIcon />,
    path: [PATHS.achievements, PATHS.addAchievement, PATHS.editAchievement],
  },
  {
    name: 'Notifications',
    icon: <NotificationsIcon />,
    path: [
      PATHS.notifications,
      PATHS.editNotificationQuotes,
      PATHS.editNotificationRegular,
      PATHS.addNotificationQuotes,
      PATHS.addNotificationRegular,
    ],
  },
  { name: 'Settings', icon: <SettingsIcon />, path: [PATHS.settings] },
];

export const TITLE_HEADER = {
  [PATHS.home]: 'Dashboard',
  [PATHS.dashboard]: 'Dashboard',
  [PATHS.exercises]: 'Exercises',
  [PATHS.addExercise]: 'Create exercise',
  [PATHS.trainings]: 'Trainings',
  [PATHS.addTraining]: 'Create training',
  [PATHS.users]: 'Users',
  [PATHS.achievements]: 'Achievements',
  [PATHS.settings]: 'Settings',
};

export const LABEL_CREDENTIALS_INPUT = {
  login: { placeholder: 'Enter login', subtitle: 'Login' },
  password: { placeholder: 'Enter password', subtitle: 'Password ' },
  email: {
    placeholder: 'Enter email',
    subtitle: 'Enter email',
    hint: 'You will recieve temporary password on your email ',
  },
  temporaryPassword: {
    placeholder: 'Enter password',
    subtitle: 'Temporary password',
  },
  passwordConfirm: {
    placeholder: 'Repeat password',
    subtitle: 'Repeat password',
  },
  newPassword: {
    placeholder: 'Enter password',
    subtitle: 'Create password ',
    hint: '8 symbols length, 1 Uppercase letter, 1 special character',
  },
};

export const TYPE_CREDENTIALS_INPUT = {
  login: 'email',
  password: 'password',
  email: 'email',
  passwordConfirm: 'password',
  temporaryPassword: 'password',
  newPassword: 'password',
};

export const NAME_CREDENTIALS_INPUT = {
  [PATHS.home]: ['login', 'password'],
  [PATHS.forgotPassword]: ['email'],
  [PATHS.temporaryPassword]: ['temporaryPassword'],
  [PATHS.newPassword]: ['newPassword', 'passwordConfirm'],
  [PATHS.registration]: ['newPassword', 'passwordConfirm'],
};

export const BUTTON_CREDENTIALS_TEXT = {
  [PATHS.home]: 'login',
  [PATHS.forgotPassword]: 'Send password',
  [PATHS.temporaryPassword]: 'Set new password',
  [PATHS.newPassword]: 'Set new password',
  [PATHS.registration]: 'Create account',
};

export const TITLES_CREDENTIALS_PAGES = {
  [PATHS.home]: 'Admin login',
  [PATHS.forgotPassword]: 'Reset password',
  [PATHS.temporaryPassword]: 'Reset password',
  [PATHS.newPassword]: 'Reset password',
  [PATHS.registration]: 'Admin account setup',
};

export const ERROR_MESSAGE = {
  'User not found': 'Incorrect login or password',
  'User with email': 'User with this email already exists',
  'Incorrect password': 'Incorrect login or password',
};

export const EXERCISES_HEADER_TABLE_TITLES = [
  { name: 'id', className: 'id', isShouldSort: true },
  { name: 'Exercise name', className: 'exerciseName', isShouldSort: true },
  { name: 'Duration', className: 'duration', isShouldSort: false },
  { name: '', className: 'edit', isShouldSort: false },
  { name: '', className: 'delete', isShouldSort: false },
];

export const TRAININGS_HEADER_TABLE_TITLES = [
  { name: 'Date', className: 'date', isShouldSort: true },
  { name: 'Training name', className: 'trainingName', isShouldSort: true },
  { name: 'Duration', className: 'duration', isShouldSort: true },
  // { name: 'Exercises', className: 'exercises', isShouldSort: true },
  { name: 'Fitness level', className: 'fitnessLvel', isShouldSort: true },
  { name: 'Goal', className: 'goal', isShouldSort: true },
  { name: 'Availability', className: 'availability', isShouldSort: true },
  { name: 'WOD', className: 'wod', isShouldSort: true },
  { name: '', className: 'edit', isShouldSort: false },
  { name: '', className: 'delete', isShouldSort: false },
];

export const USERS_HEADER_TABLE_TITLES = [
  {
    name: 'User Registration Date',
    className: 'userRegistrationDate',
    isShouldSort: true,
  },
  { name: 'First name', className: 'firstName', isShouldSort: true },
  { name: 'Last name', className: 'secondName', isShouldSort: true },
  { name: 'Email', className: 'email', isShouldSort: true },
  { name: 'Subscription', className: 'subscription', isShouldSort: true },
  { name: 'First subscribed', className: 'first_sub', isShouldSort: true },
  { name: 'Last transaction', className: 'last_trans', isShouldSort: true },
  // { name: 'LTV', className: 'ltv', isShouldSort: true },
];

export const USER_TRANSACTIONS_HEADER_TABLE_TITLES = [
  { name: 'time', className: 'time', isShouldSort: true },
  { name: 'amount', className: 'amount', isShouldSort: true },
  { name: 'code', className: 'code', isShouldSort: true },
  {
    name: 'subscription type at the time of transaction',
    className: 'type',
    isShouldSort: true,
  },
  { name: 'status', className: 'status', isShouldSort: true },
];

export const USER_STATISTICS_HEADER_TABLE_TITLES = [
  { name: 'time', className: 'time', isShouldSort: true },
  { name: 'workout name / id', className: 'workout', isShouldSort: true },
  { name: 'status', className: 'status', isShouldSort: true },
];

export const ACHIEVEMENTS_HEADER_TABLE_TITLES = [
  { name: 'ID', className: 'id', isShouldSort: false },
  { name: 'Name', className: 'name', isShouldSort: false },
  { name: 'Requirement', className: 'requirement', isShouldSort: false },
  { name: 'Tag', className: 'tag', isShouldSort: false },
  { name: '', className: 'edit', isShouldSort: false },
  { name: '', className: 'delete', isShouldSort: false },
];

export const NOTIFICATIONS_REGULAR_HEADER_TABLE_TITLES = [
  { name: 'Title', className: 'title', isShouldSort: false },
  { name: 'Message', className: 'message', isShouldSort: false },
  { name: '', className: 'edit', isShouldSort: false },
  { name: '', className: 'delete', isShouldSort: false },
];

export const NOTIFICATIONS_QUOTES_HEADER_TABLE_TITLES = [
  { name: 'Title', className: 'title', isShouldSort: false },
  { name: 'Message', className: 'message', isShouldSort: false },
  { name: '', className: 'edit', isShouldSort: false },
  { name: '', className: 'delete', isShouldSort: false },
];

export const ITEMS_PER_PAGE = 20;
export const EXERCISES_TAKE = 50;

export const DATA_SORT_USER_TRANSACTIONS = {
  time: ['timeASC', 'timeDESC'],
  amount: ['amountASC', 'timeDESC'],
  'subscription type at the time of transaction': [
    'subscriptionASC',
    'subscriptionDESC',
  ],
  status: ['statusASC', 'statusDESC'],
};

export const DATA_SORT_USER_STATISTICS = {
  time: ['timeASC', 'timeDESC'],
  'workout name / id': ['workoutASC', 'workoutDESC'],
  status: ['statusASC', 'statusDESC'],
};

export const DATA_SORT_EXERCISE = {
  id: ['IdASC', 'IdDESC'],
  'Exercise name': ['NameASC', 'NameDESC'],
  Duration: ['DurationASC', 'DurationDESC'],
};

export const DATA_SORT_TRAINING = {
  Date: ['CreatedAtASC', 'CreatedAtDESC'],
  'Training name': ['NameASC', 'NameDESC'],
  Duration: ['DurationASC', 'DurationDESC'],
  // Exercises: ['ExerciseASC', 'ExerciseDESC'],
  'Fitness level': ['ActivityLevelASC', 'ActivityLevelDESC'],
  Goal: ['GoalsASC', 'GoalsDESC'],
  Availability: ['AvailabilityASC', 'AvailabilityDESC'],
  WOD: ['WorkoutOfTheDayASC', 'WorkoutOfTheDayDESC'],
};

export const DATA_SORT_USERS = {
  'User Registration Date': ['createdAtASC', 'createdAtDESC'],
  'First name': ['firstnameASC', 'firstnameDESC'],
  'Last name': ['lastnameASC', 'lastnameDESC'],
  Email: ['emailASC', 'emailDESC'],
  Subscription: ['subscriptionASC', 'subscriptionDESC'],
  'First subscribed': ['firstSubscriptionDateASC', 'firstSubscriptionDateDESC'],
  'Last transaction': ['lastTransactionDateASC', 'lastTransactionDateDESC'],
  // LTV: ['ltvASC', 'ltvDESC'],
};

export const DATA_SORT_ACHIEVEMENTS = {
  // Id: ["IdASC", "IdDESC"],
  // Name: ["NameASC", "NameDESC"],
  // Tag: ["TagASC", "TagDESC"],
};

export const IS_LOADING_BY_ENDPOINTS = {
  getExercise: 'get/training-exercise',
  postExercise: 'post/training-exercise',
  deleteExercise: 'delete/training-exercise',
  updateExercise: 'put/training-exercise',

  getTraining: 'get/training',
  postTraining: 'post/training',
  deleteTraining: 'delete/training',
  updateTraining: 'put/training',

  getUsers: 'get/users',
  getUser: 'get/user',
  postUser: 'post/auth',
  postAdmin: 'post/users',
  putAdmin: 'put/users',

  getAchievement: 'get/achievement',
  postAchievement: 'post/achievement',
  updateAchievement: 'put/achievement',

  postNotification: 'post/notification',
  updateNotification: 'put/notification',
  deleteNotificationQuote: 'delete/notification',
  deleteNotificationRegular: 'delete/notification',
};

export const defaultFirstDay = moment().subtract(6, 'months');
export const defaultLastDay = moment();

export const MOCKED_ACHIEVEMENT_EVENT_CODE = 29;
