const getRefreshToken = () => localStorage.getItem("refreshToken");

const getAccessToken = () => localStorage.getItem("accessToken");

const setRefreshToken = (token) => localStorage.setItem("refreshToken", token);

const setAccessToken = (token) => localStorage.setItem("accessToken", token);

const setTempoToken = (token) => localStorage.setItem("tempoToken", token);

const getTempoToken = () => localStorage.getItem("tempoToken");

const removeTempoToken = () => localStorage.removeItem("tempoToken");

const removeTokens = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
};

const TokenService = {
  getRefreshToken,
  getAccessToken,
  setRefreshToken,
  setAccessToken,
  removeTokens,
  setTempoToken,
  getTempoToken,
  removeTempoToken,
};

export default TokenService;
