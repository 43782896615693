import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  menuList: {
    padding: 0,
  },
  menuItem: {
    textDecoration: "none",
    color: "inherit",
    "& .MuiButtonBase-root": {
      "& > div": {
        padding: 0,
        "&:first-child": {
          minWidth: 30,
        },
      },
    },
  },
  logo: {
    justifyContent: "center",
    height: 90,
    "& img":{
      maxWidth: "100%",
      width: "100%",
      height:"auto",
      display:"flex",
    }
  }
}));
